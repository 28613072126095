import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import {
  formatDatestring,
  getDisplayDateFromStartEndDateString,
  getNumPostfix,
  randomString,
  useScrollToLocation,
} from "../../utils";
import { toast } from "react-hot-toast";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { usePageVisibility } from "react-page-visibility";
import {
  updateAnalytics,
  WebsiteAnalyticsActionsEnums,
} from "../../services/AnalyticsService";

const tag = "industrialtech";

export default function Request({ user }) {
  const [pageUrl, setPageUrl] = useState(
    window.location.origin + window.location.pathname
  );
  const isPageVisible = usePageVisibility();
  const [isShown, setIsShown] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [events, setEvents] = useState([]);
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });

  useScrollToLocation();

  // companies
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const api = new ApiService();
        const response = await api.get(
          `landmark/portfolio/getCompanies/${tag}`
        );
        console.log(
          `fetchCompanies() - landmark/portfolio/getCompanies/${tag}`,
          response
        );
        if (response && response.length && response[0].companyId) {
          setCompanies(response);
        } else {
          throw "Couldn't get companies";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Couldn't load data from server");
      }
    };
    fetchCompanies();
  }, []);

  // events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const api = new ApiService();
        const response = await api.get(`landmark/events/getEvents/${tag}`);
        console.log(
          `fetchEvents() - landmark/events/getEvents/${tag}`,
          response
        );
        if (response && response.length && response[0].eventId) {
          // sort events by start date
          response.sort((a, b) => {
            const aIsTBD =
              a.startDate.includes("TBD") || a.startDate.includes("TBC");
            const bIsTBD =
              b.startDate.includes("TBD") || b.startDate.includes("TBC");

            // Move TBD/TBC to the end
            if (aIsTBD && !bIsTBD) return 1;
            if (!aIsTBD && bIsTBD) return -1;
            if (aIsTBD && bIsTBD) return 0;

            // Otherwise, sort by startDateEpoch
            return a.startDateEpoch - b.startDateEpoch;
          });
          setEvents(response);
        } else {
          throw "Couldn't get events";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Couldn't load data from server");
      }
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    if (user) {
      setForm({
        events: [],
        companies: [],
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        company: user.company || "",
        jobTitle: user.jobTitle || "",
        email: user.email || "",
        notes: "",
      });

      if (user.contactId) {
        // analytics - VISIT
        updateAnalytics({
          contactId: user.contactId,
          url: pageUrl,
          action: WebsiteAnalyticsActionsEnums.VISIT,
          actionData: {},
        });
      }
    }
  }, [user]);

  // analytics - TIME_SPENT
  useEffect(() => {
    const intervalTime = 10000;
    const updateTimeSpentAnalytics = async () => {
      console.log(`updateTimeSpentAnalytics()`);
      console.log(`user.contactId`, user.contactId);
      console.log(`isPageVisible`, isPageVisible);
      if (user.contactId && isPageVisible) {
        // analytics - TIME_SPENT
        updateAnalytics({
          contactId: user.contactId,
          url: pageUrl,
          action: WebsiteAnalyticsActionsEnums.TIME_SPENT,
          actionData: intervalTime,
        });
      }
    };

    // set up interval to call updateTimeSpentAnalytics every 5 seconds
    const intervalId = setInterval(updateTimeSpentAnalytics, intervalTime);

    // clean up interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const [form, setForm] = useState({
    events: [],
    companies: [],
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    notes: "",
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  // optionsName - e.g. expertiseOptions
  const optionOnChange = (e, optionsName) => {
    if (e.target.checked) {
      let arr = [...form[optionsName]];
      arr.push(e.target.id);
      const obj = {};
      obj[optionsName] = arr;
      updateForm(obj);
    } else {
      const obj = {};
      obj[optionsName] = form[optionsName].filter(
        (item) => item !== e.target.id
      );
      updateForm(obj);
    }
  };

  const clearForm = () => {
    setForm({
      events: [],
      companies: [],
      firstName: "",
      lastName: "",
      company: "",
      jobTitle: "",
      email: "",
      notes: "",
    });
  };

  const submitForm = async () => {
    if (!formValid) {
      console.log(`submitForm() - form is invalid`, form);
      return;
    }
    console.log(`submitForm()`, form);
    try {
      const api = new ApiService();
      const response = await api.post(`landmark/portfolio/submitForm`, {
        form,
        tag,
      });
      console.log(`submitForm() - response`, response);
      // toast.success("Your response has been logged. Thank you!");
      setIsShown(true);
      // setCompanies(response);
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error("An error occurred when submitting your response.");
    }
  };

  if (!companies || !companies.length) {
    return null;
  }

  let formValid = false;
  let formErrorMsg = ``;
  // validate form
  const validateForm = () => {
    const { events, companies, firstName, lastName, company, jobTitle, email } =
      form;

    if (!companies.length && !events.length) {
      formErrorMsg = "Please select at least one Portfolio Company or Event";
      return false;
    }
    if (!firstName.length) {
      formErrorMsg = "Please enter your first name";
      return false;
    }
    if (!lastName.length) {
      formErrorMsg = "Please enter your last name";
      return false;
    }
    if (!company.length) {
      formErrorMsg = "Please enter your company";
      return false;
    }
    if (!jobTitle.length) {
      formErrorMsg = "Please enter your job title";
      return false;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      formErrorMsg = "Please enter a valid email address";
      return false;
    }
    return true;
  };
  formValid = validateForm();
  const submitDisabledClass = formValid ? "" : "disabled";

  const OptionCheckbox = ({ option, optionsName }) => {
    const id = `${option}`;
    return (
      <label htmlFor={id} className="check">
        <div className="check__box">
          <input
            type="checkbox"
            id={id}
            checked={form[optionsName].some((p) => p === option)}
            onChange={(event) => optionOnChange(event, optionsName)}
          />
          <span>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
              alt="icon"
            />
          </span>
        </div>
        <div className="check-label">{option}</div>
      </label>
    );
  };

  return (
    <>
      <div className="cap-width request-section">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: 20, y: 0 }} // Set your initial state
          animate={inView ? { opacity: 1, y: 0, x: 0 } : {}} // Set your animation properties
          transition={{ duration: 0.9, ease: "easeOut" }}
        >
          <div></div>
        </motion.div>
        {events && events.length ? (
          <div
            className="options-list"
            id="events"
            style={{ marginBottom: "62px" }}
          >
            {/* <div className="option-container" style={{ minHeight: "unset" }}>
            <div
              className="option-header"
              style={{ borderRadius: "8px", minHeight: "13px" }}
            ></div>
          </div> */}
            <div className="option-heading-text">
              If you are interested in our dealmaking events,
              <br /> please select one or more below:
            </div>
            {events
              // .sort((a, b) =>
              //   a.title.toLowerCase().localeCompare(b.title.toLowerCase())
              // )
              .filter((e) => !e.hidden)
              .map((item, index) => {
                return (
                  <RequestItemEvent
                    itemData={item}
                    key={index}
                    updateForm={updateForm}
                    form={form}
                    index={index}
                    pageUrl={pageUrl}
                    user={user}
                  />
                );
              })}
          </div>
        ) : null}

        <div className="options-list" id="portfolio">
          {/* <div className="option-container" style={{ minHeight: "unset" }}>
            <div
              className="option-header"
              style={{ borderRadius: "8px", minHeight: "13px" }}
            ></div>
          </div> */}
          <div className="option-heading-text">
            To request a meeting with our portfolio companies,
            <br /> please select one or more below:
          </div>

          {companies && companies.length
            ? companies
                .sort((a, b) =>
                  a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                )
                .map((item, index) => {
                  return (
                    <RequestItemCompany
                      itemData={item}
                      key={index}
                      updateForm={updateForm}
                      form={form}
                      index={index}
                      pageUrl={pageUrl}
                      user={user}
                    />
                  );
                })
            : null}
        </div>
      </div>
      <div className="cap-width slightly-wider">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 0 }} // Set your initial state
          animate={inView ? { opacity: 1, y: 0 } : {}} // Set your animation properties
          transition={{ duration: 1.4, ease: "easeOut" }}
        >
          <div className="form-container" id="join">
            <div className="requestForm">
              <h3>Your Information</h3>
              <div className="requestForm__inputs">
                <label className="input__outer sm">
                  <h5>First Name</h5>
                  <div className="input">
                    <input
                      type="name"
                      value={form.firstName}
                      onChange={onChangeInput("firstName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Last Name</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.lastName}
                      onChange={onChangeInput("lastName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Company</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.company}
                      onChange={onChangeInput("company")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Job title</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.jobTitle}
                      onChange={onChangeInput("jobTitle")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Email</h5>
                  <div className="input">
                    <input
                      type="email"
                      value={form.email}
                      onChange={onChangeInput("email")}
                    />
                  </div>
                </label>
                {/* <label className="input__outer sm">
                  <h5>Referred By (optional)</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.referredBy}
                      onChange={onChangeInput("referredBy")}
                    />
                  </div>
                </label> */}
                <label className="input__outer sm">
                  <h5>Tell us about your business goals (optional)</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.notes}
                      onChange={onChangeInput("notes")}
                    />
                  </div>
                </label>
              </div>
              <div className="requestForm submit">
                {!isShown && (
                  <div className="submit-wrapper">
                    <button
                      type="submit"
                      onClick={submitForm}
                      className={`button pink-btn ${submitDisabledClass}`}
                    >
                      SUBMIT
                    </button>
                    {formErrorMsg.length ? (
                      <div className="form-invalid-msg">{formErrorMsg}</div>
                    ) : null}
                  </div>
                )}
              </div>
              <AnimatePresence>
                {/* {isShown && <SuccessModal setIsShown={setIsShown} />} */}
                {isShown && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div className="submit-success-msg">
                      Thank you for your submission. One of the leaders from our
                      Industrial Technology Practice will be in touch shortly to
                      follow up. We look forward to connecting with you.
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>
      </div>
      {/* <div className="disclaimer">
        Landmark Events are not affiliated with CES®
      </div> */}
      <div style={{ height: "400px" }}></div>
    </>
  );
}

const RequestItemCompany = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.companies];
      arr.push(props.itemData);
      props.updateForm({ companies: arr });
      console.log(
        `RequestItemCompany() - form companies updated - company added`,
        arr
      );
    } else {
      const adjustedArr = props.form.companies.filter(
        (item) => item.companyId !== props.itemData.companyId
      );
      props.updateForm({
        companies: adjustedArr,
      });
      console.log(
        `RequestItemCompany() - form companies updated - company removed`,
        adjustedArr
      );
    }
  };

  // analytics for an element click
  const registerElemClick = async (elem) => {
    // analytics - ELEM_CLICK
    if (props.user.contactId) {
      await updateAnalytics({
        contactId: props.user.contactId,
        url: props.pageUrl,
        action: WebsiteAnalyticsActionsEnums.ELEM_CLICK,
        actionData: { elem },
      });
    }
  };

  let checkboxMode = true;

  const randomId = randomString(6);

  const {
    companyId,
    title,
    shortDescription,
    longDescription,
    url,
    imageUrl,
    financials,
    competitor,
    funding,
    valuation,
    majorInvestors,
    recentDeal,
    transaction,
    tags,
    owners,
    createdTime,
  } = props.itemData;

  const StartupFieldRow = (field, data, anchorMode) => {
    if (!data || !data.length) {
      return null;
    }

    return (
      <div className="row w-100 startup-row">
        <div className="col-12 col-md-3">
          <div className="startup-field">{field}:</div>
        </div>
        <div className="col-12 col-md-9">
          {anchorMode ? (
            <a href={data} target="_blank">
              <div className="startup-data">{data}</div>
            </a>
          ) : (
            <div className="startup-data">{data}</div>
          )}
        </div>
      </div>
    );
  };

  if (checkboxMode) {
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: 20, y: 0 }} // Set your initial state
        animate={inView ? { opacity: 1, y: 0, x: 0 } : {}} // Set your animation properties
        transition={{ duration: 0.9, ease: "easeOut" }}
      >
        <div className="option-container">
          <div className="outer-container">
            <div className={`checkbox-container bg-${props.index}`}>
              <div className="check__box adj">
                <input
                  type="checkbox"
                  id={randomId}
                  checked={checked}
                  onChange={onChange}
                />
                <span>
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                    alt="icon"
                  />
                </span>
              </div>
            </div>
            <div className="content-container">
              <div className="row w-100 startup-row">
                <div className="col-12 col-md-2 startup-img-col">
                  <div className="startup-img">
                    <img className="img-fluid" src={imageUrl} />
                  </div>
                </div>
                <div className="col-12 col-md-10">
                  <div className="company-content-container">
                    <div className="startup-title">{title}</div>
                    <div className="startup-short-desc">{shortDescription}</div>
                    <a
                      href={url}
                      target="_blank"
                      onClick={() => registerElemClick(url)}
                    >
                      <div className="startup-url">{url}</div>
                    </a>
                    <div className="startup-long-desc">{longDescription}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
  // legacy
  if (checkboxMode) {
    return (
      <div className="req-item">
        <div className="req-item-content">
          <div className="req-item-upper">
            <div className="check__box adj">
              <input
                type="checkbox"
                id={randomId}
                checked={checked}
                onChange={onChange}
              />
              <span>
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                  alt="icon"
                />
              </span>
            </div>
            <div className="req-item-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="req-item-header">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">{props.itemData.shortDescription}</p>
              <p className="sm long-desc">{props.itemData.longDescription}</p>
            </div>
          </div>
          {/* <div className="req-item-lower">
        </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="req-item">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <div className="req-item-content">
        <div className="req-item-upper">
          <div className="req-item-img">
            <img className="img-fluid" src={props.itemData.imageUrl} />
          </div>
          <div className="req-item-header">
            <h5>{props.itemData.title}</h5>
            <p className="sm short-desc">{props.itemData.shortDescription}</p>
            <p className="sm long-desc">{props.itemData.longDescription}</p>
          </div>
        </div>
        {/* <div className="req-item-lower">
        </div> */}
      </div>
    </div>
  );

  return (
    <div className="requestFormItem">
      <div className="requestFormItem__inner">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="requestFormItem__content">
          <div className="d-flex event-container">
            <div className="company-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="event-info">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">{props.itemData.shortDescription}</p>
              <p className="sm long-desc">{props.itemData.longDescription}</p>
              <p className="sm">{props.itemData.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RequestItemEvent = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.events];
      arr.push(props.itemData);
      props.updateForm({ events: arr });
      console.log(
        `RequestItemCompany() - form events updated - event added`,
        arr
      );
    } else {
      const adjustedArr = props.form.events.filter(
        (item) => item.eventId !== props.itemData.eventId
      );
      props.updateForm({
        events: adjustedArr,
      });
      console.log(
        `RequestItemCompany() - form events updated - event removed`,
        adjustedArr
      );
    }
  };

  // analytics for an element click
  const registerElemClick = async (elem) => {
    // analytics - ELEM_CLICK
    if (props.user.contactId) {
      await updateAnalytics({
        contactId: props.user.contactId,
        url: props.pageUrl,
        action: WebsiteAnalyticsActionsEnums.ELEM_CLICK,
        actionData: { elem },
      });
    }
  };

  const randomId = randomString(6);

  const {
    title,
    imageUrl,
    startDate,
    endDate,
    endDateEpoch,
    location,
    url,
    description,
  } = props.itemData;

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: 20, y: 0 }} // Set your initial state
      animate={inView ? { opacity: 1, y: 0, x: 0 } : {}} // Set your animation properties
      transition={{ duration: 0.9, ease: "easeOut" }}
    >
      <div className="option-container">
        <div className="outer-container">
          <div className={`checkbox-container bg-${props.index}`}>
            <div className="check__box adj">
              <input
                type="checkbox"
                id={randomId}
                checked={checked}
                onChange={onChange}
              />
              <span>
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                  alt="icon"
                />
              </span>
            </div>
          </div>
          <div className="content-container">
            <div className="row w-100 startup-row">
              <div className="col-12 col-md-2 startup-img-col">
                <div className="startup-img">
                  <img alt="event logo" className="img-fluid" src={imageUrl} />
                </div>
              </div>
              <div className="col-12 col-md-10">
                <div className="company-content-container">
                  <div className="startup-title">{title}</div>
                  <div className="startup-short-desc">
                    {getDisplayDateFromStartEndDateString(
                      startDate,
                      endDate,
                      endDateEpoch
                    )}{" "}
                    - {location}
                  </div>
                  <a
                    href={url}
                    target="_blank"
                    onClick={() => registerElemClick(url)}
                  >
                    <div className="startup-url">{url}</div>
                  </a>
                  <div className="startup-long-desc">{description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
